import "htmx.org";
import "@/client/modules/htmx-errors";
import "@/client/modules/video-player";

import _hyperscript from "hyperscript.org";

_hyperscript.browserInit();

import { buildCalendar } from "@/client/modules/calendar";

const calendarElement = document.querySelector(
  "#events-calendar",
) as HTMLElement;

if (calendarElement) {
  buildCalendar(calendarElement);
}

document.addEventListener("htmx:afterRequest", function () {
  // Put the JS code that you want to execute here
  const calendarElement = document.querySelector(
    "#events-calendar",
  ) as HTMLElement;

  if (calendarElement) {
    buildCalendar(calendarElement);
  }
});

const ssoLoginForm = document.querySelector("form#sso-login") as
  | HTMLFormElement
  | undefined;

if (ssoLoginForm) ssoLoginForm.submit();
