document.body.addEventListener("htmx:responseError", function (event: CustomEvent) {
  const errorTarget = document.getElementById("client-error") as HTMLDialogElement;

  errorTarget.querySelector("button")?.addEventListener(
    "click",
    function () {
      console.warn("click");
      errorTarget.close();
    },
    { once: true }
  );

  const errorTargetMessage = errorTarget.querySelector("output") as HTMLOutputElement;

  if (event.detail.xhr) {
    // Server error with response contents, equivalent to htmx:responseError
    const xhr = event.detail.xhr;
    errorTargetMessage.innerHTML = `<p class="font-bold">Error ${xhr.status}</p> ${xhr.responseText}`;
    errorTarget.showModal();
  } else {
    // Unspecified failure, probably network error
    errorTargetMessage.innerHTML = `<p class="font-bold">Error general</p> No pudo completarse la solicitud. Por favor, inténtelo de nuevo.`;
    errorTarget.showModal();
  }

  const flashMessage = document.getElementById("flash") as HTMLDialogElement;
  flashMessage.close();
} as EventListener);
