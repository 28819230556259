import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import esLocale from "@fullcalendar/core/locales/es";

export const buildCalendar = (element: HTMLElement) => {
  const events = JSON.parse(element.dataset.events || "[]");

  const calendarInstance = new Calendar(element, {
    plugins: [dayGridPlugin],
    initialView: "dayGridMonth",
    locale: esLocale,
    nowIndicator: true,
    firstDay: 1,
    events,
    headerToolbar: {
      left: "prev,next",
      center: "title",
      right: "dayGridMonth,dayGridYear",
    },
    displayEventTime: false,
  });

  calendarInstance.render();
};
